import { setRegisteredData } from './forms/registered-data'

$('[name="personal_data[billType]"]').on('change', function () {
  if ($(this).val() != 'Paragon') {
    $('input[name="personal_data[billPos]"]').hide()
    $('input[name="personal_data[billPos]"]').prop('required', false)
    $('input[name="personal_data[billPos]"]').val('')
  } else {
    $('input[name="personal_data[billPos]"]').show()
    $('input[name="personal_data[billPos]"]').prop('required', true)
  }
}).trigger('change')

$('[name="personal_data[emailRepeat]"]').each(function () {
  let $email = $('[name="personal_data[email]"]', this.form.elements)
  let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements)
  $($email).add($emailRepeat).on('input', function () {
    $emailRepeat.toggleValid($email.val() == $emailRepeat.val())
  }).trigger('change')
})

$('.application-form').on('submit-success', function (event, data) {
  $('.application').removeClass('show')
  $('#application-thanks').modal('show')
  $('html, body').animate({
    scrollTop: 0
  }, 300)
  setRegisteredData(data, '#application-thanks')
})

$('.game-run').on('click', function () {
  // page swap
  $('.page').addClass('d-none')
  $('.page-result').removeClass('d-none')
  $('.page-result').css('display', 'block')

  // modal (uncomment if you need modal instead of page swap)
  //$('#modal-form-application').modal('show');
})

$('.result-try-again').on('click', function () {
  $('.page').addClass('d-none')
  $('.page-form').removeClass('d-none')
})

$('[name="personal_data[noPesel]"]').on('change', function () {
  $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'))
  let fields = [
    '[name="personal_data[street]"]',
    '[name="personal_data[streetNr]"]',
    '[name="personal_data[flatNr]"]',
    '[name="personal_data[zipCode]"]',
    '[name="personal_data[city]"]',
  ]
  $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'))
}).trigger('change')

$('.winner-form').on('submit-success', function (event, data) {
  setRegisteredData(data, '#winner')

  // page swap
  $('#winner').addClass('filled')

  $('html, body').animate({
    scrollTop: 0
  }, 300)

  event.preventDefault()
  $(this).removeClass('was-validated')
  $(this).find('.has-invalid-feedback-ajax').ajaxInvalidFeedback('destroy')
  $(this).find('.is-valid').removeClass('is-valid')
  $(this).find('.is-invalid').removeClass('is-invalid')
  // modal (uncomment if you need modal instead of page swap)
  //$('#modal-form-winner').modal('show');
})

$('.contact-form').on('submit-success', function (event, data) {
  $('#contact-thanks').modal('show')
})
