const appendData = (data = null, list = null, text = null) => {
  data ? list.innerHTML += `<li><strong>${text}</strong>: ${data}</li>` : null
}

const setFile = (element, url, filename) => {
  element.forEach(file => {
    let fileExtension = url.split('.').pop().toLowerCase()
    let isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension)
    if (isImage) {
      file.querySelector('img').setAttribute(`src`, `${url}`)
    } else {
      file.querySelector('img').setAttribute(`src`, file.querySelector('img').dataset.src)
    }
    file.querySelector('.registered-data-file-name').textContent = filename
    file.querySelector('.registered-data-file-name').setAttribute(`href`, `${url}`)
    file?.classList.remove(`d-none`)
  })
}

export const setRegisteredData = (data, container = null) => {
  // Variables
  container = document.querySelector(`${container}`)
  const lists = container.querySelectorAll(`ul.registered-data-list`)
  const printButtons = container.querySelectorAll(`a.registered-data-pdf-print`)
  const files = {
    confirmation: container.querySelectorAll(`.registered-data-file-confirmation`),
    paragon: container.querySelectorAll(`.registered-data-file-paragon`),
    agreement: container.querySelectorAll(`.registered-data-file-agreement`),
  }

  lists.forEach(list => {
    // Reset list data
    list.innerHTML = ''
    // Personal basic data
    appendData(data.email, list, 'Adres e-mail uczestnika')
    appendData(data.name, list, 'Imię i nazwisko uczestnika')
    appendData(data.firstname, list, 'Imię')
    appendData(data.lastname, list, 'Nazwisko')
    appendData(data.phone, list, 'Numer telefonu')
    appendData(data.birthDay, list, 'Data urodzenia')
    appendData(data.content, list, 'Treść')
    appendData(data.iban, list, 'Numer konta bankowego')
    // Bill data
    // appendData(data.billType, list, `Typ dowodu zakupu`)
    appendData(data.billNr, list, `Numer dowodu dokonania Zakupu Promocyjnego`)
    appendData(data.billDate, list, `Data dokonania Zakupu Promocyjnego`)
    appendData(data.billNip, list, 'NIP sprzedawcy')
    appendData(data.billPos, list, 'Unikalny nr kasy fiskalnej')
    // Personal advanced data
    appendData(data.pesel, list, 'PESEL')
    appendData(data.citizenship, list, 'Obywatelstwo')
    appendData(data.identityDocumentType, list, 'Typ dowodu osobistego')
    appendData(data.identityDocumentNr, list, 'Numer dowodu osobistego')
    // Address
    appendData(data.street, list, 'Ulica')
    appendData(data.streetNr, list, 'Numer domu')
    appendData(data.flatNr, list, 'Numer mieszkania')
    appendData(data.city, list, 'Miasto')
    appendData(data.zipCode, list, 'Kod pocztowy')
    appendData(data.amount, list, 'Kwota zakupu 7Days')
  })

  printButtons?.forEach(button => {
    // Reset .PDF print URL
    button?.setAttribute('href', '#')
    // Set .PDF print URL
    data.pdf ? button?.setAttribute('href', data.pdf) : null
  })

  // Reset file data
  Object.values(files).forEach(element => {
    element.forEach(file => {
      let dataSrc = file?.dataset.src
      file?.setAttribute(`src`, dataSrc)
      file?.classList.add(`d-none`)
    })
  })
  // Set file images
  data.paragonUrl || data.confirmationUrl || data.agreementUrl ? container.querySelector(`.registered-data-files`)?.classList.remove(`d-none`) : null
  data.paragonUrl ? setFile(files?.paragon, data.paragonUrl, data.paragon) : null
  data.confirmationUrl ? setFile(files?.confirmation, data.confirmationUrl, data.confirmation) : null
  data.agreementUrl ? setFile(files?.agreement, data.agreementUrl, data.agreement) : null
}
