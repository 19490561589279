import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

$('#applicationForm').on('submit-success', function (event, data) {
  // do something with the response data
  alert('Pomyślnie przesłano formularz.', 'success')
})

$('#personal_data_billTypeCheckbox').on('change', function () {
  let checked = $(this).prop('checked')
  if (checked) {
    $('#personal_data_billType').attr('value', 'Faktura')
    $('input[name="personal_data[billPos]"]').hide()
    $('input[name="personal_data[billPos]"]').prop('required', false)
  } else {
    $('#personal_data_billType').attr('value', 'Paragon')
    $('input[name="personal_data[billPos]"]').show()
    $('input[name="personal_data[billPos]"]').prop('required', true)
  }
  $('input[name="personal_data[billPos]"]').val('')
})

$('#personal_data_pesel_checkbox').on('change', function () {
  let checked = $(this).prop('checked')
  $('#personal_data_pesel').prop('disabled', checked)
  $('#personal_data_pesel').prop('required', !checked)
  $('#personal_data_pesel').val('')
  $('.fieldset-pesel').toggleClass('d-none').prop('disabled', !checked)
})

$('input.iban-check').each(function () {
  $(this).on('change', function () {
    let checked = $(this).val()
    $('input.iban-check').prop('checked', false)
    $(this).prop('checked', true)
    $('fieldset.fieldset-iban-record-yes').toggleClass('d-none').prop('disabled', (i, v) => !v)
    $('fieldset.fieldset-iban-record-no').toggleClass('d-none').prop('disabled', (i, v) => !v)
  })
})

$(document).ready(function () {
  $(document).ajaxStart(function () {
    $('body').addClass('loading')
  })
  $(document).ajaxStop(function () {
    $('body').removeClass('loading')
  })
})

$('#application-thanks').on('hidden.bs.modal', function () {
  window.location.href = '/'
})

const applicationSwiperEl = document.querySelector('.upload-help .swiper')
if (applicationSwiperEl) {
  const applicationSwiper = new Swiper(document.querySelector('.upload-help .swiper'), {
    slidesPerView: 1,
    loop: true,
    touchRatio: 0.1,
    navigation: {
      prevEl: document.querySelector('.upload-help .swiper-button-prev'),
      nextEl: document.querySelector('.upload-help .swiper-button-next')
    },
    modules: [Navigation]
  })
  document.getElementById('show-upload-help').onclick = function () {
    document.querySelector('#upload-file-modal .content').style.display = 'none'
    document.querySelector('#upload-file-modal .upload-help').style.display = 'block'
  }
  document.querySelector('#upload-file-modal .upload-help .btn-primary').onclick = function () {
    document.querySelector('#upload-file-modal .content').style.display = 'block'
    document.querySelector('#upload-file-modal .upload-help').style.display = 'none'
  }
}
