const navigation = document.getElementById('navigation')
const navigationToggler = navigation?.querySelector('.navbar-toggler')
const dropdown = document.getElementById('navigation-dropdown')

dropdown?.querySelectorAll('.nav-item').forEach((item) => {
  if (!item.classList.contains('dropdown')) {
    item.addEventListener('click', () => {
      toggleDropdown()
    })
  }
})

const toggleDropdown = () => {
  if (navigationToggler?.getAttribute('aria-expanded') == 'true') {
    navigationToggler?.click()
  }
}

// header primary

$('.header-primary__hamburger').click(function (e) {

  e.preventDefault()
  e.stopPropagation()

  $(this).children('.hamburger').toggleClass('hamburger--active')
  $('.header-primary').toggleClass('header-primary--is-open')
  // $('html').toggleClass('disable-scroll');

})

$('.header-primary__nav').click(function (e) {
  e.stopPropagation()
})

$('body').click(function () {

  $('.header-primary__hamburger .hamburger').removeClass('hamburger--active')
  $('.header-primary').removeClass('header-primary--is-open')
  $('html').removeClass('disable-scroll')

})

$('.header-primary__nav a').click(function (e) {

  let href = $(this).attr('href'),
    sectionName = href.split('#')[1],
    section = $('#' + sectionName),
    sectionExist = section.length

  if (sectionExist) {
    $('.header-primary__hamburger .hamburger').removeClass('hamburger--active')
    $('.header-primary').removeClass('header-primary--is-open')
    $('html').removeClass('disable-scroll')

    if (sectionName === 'wez-udzial') {
      $('#upload-file-modal').modal('show')
    }
  }

})

// scroll to

$('a[href*="#"]').not('[href="#"]').click(function (e) {

  console.log($(this).attr('href'), $(this).attr('href').match(/^\/#\D+$/))
  if (!$(this).attr('href').match(/^\/#\D+$/)) {
    e.preventDefault()

    let href = $(this).attr('href'),
      sectionName = href.split('#')[1],
      section = $('#' + sectionName),
      sectionOffsetTop = section.offset().top

    $('html, body').animate({
      scrollTop: sectionOffsetTop
    }, 300)

    return false
  }

})
