window.$ = $

import { ClickScrollPlugin, OverlayScrollbars } from 'overlayscrollbars'
import 'bootstrap'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pl'
import 'bootstrap'

import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

OverlayScrollbars.plugin(ClickScrollPlugin)

window.alert = function (message, type = 'danger') {
  let $toast = $(`
        <div class="toast align-items-center text-white bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body d-flex" data-bs-dismiss="toast">
                <div class='me-2'>
                    ${message}
                </div>
                <button type="button" class="btn-close btn-close-white ms-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `)
  $('.toast-container').prepend($toast)
  $toast.toast('show')
}

// przenosi pojedyncze znaki z końca linijki na początek następnej
export function addNbsps (el) {
  $(el).find(':not(iframe):not(style):not(script)').add(el).contents().filter((i, n) => n.nodeType == 3).each(function () {
    var startWhitespace = /^\s/.test(this.nodeValue) ? ' ' : ''
    var endWhitespace = /\s$/.test(this.nodeValue) ? ' ' : ''
    this.nodeValue = startWhitespace + (' ' + this.nodeValue + ' ').replace(/(\s\w)\s+/g, '$1\xA0').trim() + endWhitespace
  })
}

addNbsps('body')

$('[data-bs-toggle="tooltip"]').tooltip()

$.fn.initJs = function () {
  $(window).trigger('init', this)
  return this
}
Object.defineProperty($, 'init', {
  set (fn) {
    $(window).on('init', function (e, el) {
      fn(el, e)
    })
  }
})
window.addEventListener('DOMContentLoaded', () => $('body').initJs())

// s rules

let sRulesSlider

$('.s-rules__list .swiper').each(function () {

  sRulesSlider = new Swiper($(this)[0], {
    slidesPerView: 'auto',
    touchRatio: 0.1,
    navigation: {
      prevEl: $(this).siblings('.swiper-button-prev')[0],
      nextEl: $(this).siblings('.swiper-button-next')[0]
    },
    modules: [Navigation]
  })

})

// form

$('.form-check-more-btn').click(function (e) {

  e.preventDefault()

  $(this).hide().siblings('.form-check-more-content').fadeIn(200).css('display', 'inline')

})

// s products

let sProductsSlider

$('.s-products__slider .swiper').each(function () {

  sProductsSlider = new Swiper($(this)[0], {
    slidesPerView: 1,
    loop: true,
    touchRatio: 0.1,
    navigation: {
      prevEl: $(this).siblings('.swiper-button-prev')[0],
      nextEl: $(this).siblings('.swiper-button-next')[0]
    },
    modules: [Navigation]
  })

  sProductsSlider.on('slideChange', function (e) {
    if (window.innerWidth > 575) {
      if (e.realIndex === 0) {
        $('.s-products__nots').removeClass('d-none')
      } else {
        $('.s-products__nots').addClass('d-none')
      }
    } else {
      if (e.realIndex === 0 || e.realIndex === 1 || e.realIndex === 2) {
        $('.s-products__nots').removeClass('d-none')
      } else {
        $('.s-products__nots').addClass('d-none')
      }
    }
  })
})

// s winners

let sWinnersTableScroll

$('.s-winners__table-scroll').each(function () {
  sWinnersTableScroll = OverlayScrollbars($(this)[0], {
    scrollbars: {
      clickScroll: true
    }
  })
})

let sWinnersSlider1
let sWinnersSlider2
let sWinnersSlider3

// $('.s-winners__slider .swiper').each(function(){
//
//     sWinnersSlider = new Swiper($(this)[0], {
//         slidesPerView: 1,
//         loop: true,
//         touchRatio: 0.1,
//         navigation: {
//             prevEl: $(this).siblings('.swiper-button-prev')[0],
//             nextEl: $(this).siblings('.swiper-button-next')[0]
//         },
//         modules: [Navigation]
//     });
//
// });

sWinnersSlider1 = new Swiper($('.s-winners__slider.slider-1 .swiper')[0], {
  slidesPerView: 1,
  loop: true,
  touchRatio: 0.1,
  navigation: {
    prevEl: $('.s-winners__slider.slider-1 .swiper').siblings('.swiper-button-prev')[0],
    nextEl: $('.s-winners__slider.slider-1 .swiper').siblings('.swiper-button-next')[0]
  },
  modules: [Navigation]
})

sWinnersSlider2 = new Swiper($('.s-winners__slider.slider-2 .swiper')[0], {
  slidesPerView: 1,
  loop: true,
  touchRatio: 0.1,
  navigation: {
    prevEl: $('.s-winners__slider.slider-2 .swiper').siblings('.swiper-button-prev')[0],
    nextEl: $('.s-winners__slider.slider-2 .swiper').siblings('.swiper-button-next')[0]
  },
  modules: [Navigation]
})

sWinnersSlider3 = new Swiper($('.s-winners__slider.slider-3 .swiper')[0], {
  slidesPerView: 1,
  loop: true,
  touchRatio: 0.1,
  navigation: {
    prevEl: $('.s-winners__slider.slider-3 .swiper').siblings('.swiper-button-prev')[0],
    nextEl: $('.s-winners__slider.slider-3 .swiper').siblings('.swiper-button-next')[0]
  },
  modules: [Navigation]
})

$('.winners-calendar-container').each(function () {
  let that = this
  $('.winners-calendar', that).datepicker({
    format: 'dd.mm.yyyy',
    startDate: $(this).data('min'),
    endDate: $(this).data('max'),
    defaultViewDate: 'today',
    startView: 'days',
    minViewMode: 'days',
    maxViewMode: 'days',
    keyboardNavigation: false,
    weekStart: 1,
    language: 'pl'
  })
    .on('changeDate', function () {
      let selectedDate = $(this).datepicker('getFormattedDate').replaceAll('.2024', '')
      const index = $(that).closest('.s-winners__content').find(`.s-winners__slider .swiper-slide[data-date="${selectedDate}"]`).index()

      if ($(that).hasClass('winners-calendar-container-1')) {
        sWinnersSlider1.slideTo(index)
      } else if ($(that).hasClass('winners-calendar-container-2')) {
        sWinnersSlider2.slideTo(index)
      }
    })
    .datepicker('update', $(this).data('date'))
    .trigger('changeDate')
})

$('.application__mega-button').click(function () {
  $('.application__bill-input--additional .file-input-container label').trigger('click')
})

